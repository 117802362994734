import React, { useContext, useState } from 'react'
import { FormContext } from './FormContext';
import { FormControl, Grid, makeStyles, TextField } from '@material-ui/core'

interface TextFieldProps {
    label: string;
    name: string;
    value: string;
    possibleValues: string[];
    type: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    }
}));

export default function TextInputField(props: TextFieldProps) {
    const [value, setValue] = useState(props.value);
    const { updatedSettings } = useContext(FormContext);
    const classes = useStyles();
    const handleChangeInput = (name: any, event: any) => {
        setValue(event.target.value);
        updatedSettings[name] = event.target.value;
    }

    return (
        <Grid container spacing={3} justifyContent='center'>
            <Grid item lg={12}>
                <FormControl className={classes.formControl}>
                    <TextField
                    id={props.name + '-label'}
                    label={props.label}
                    value={value}
                    onChange={event => handleChangeInput(props.name, event)}
                    InputProps={{ inputProps: { style: { textAlign: 'center' } } }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}
