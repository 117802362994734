import React, { useEffect } from 'react';
import { FEATURES_STORE, KIDS_LOGIN_ROUTE, KIDS_LOGIN_URL_ROUTE, LOGGING_IN_TO_KIDS_MSG, LOGIN_TYPE_ROUTE, SSO_LOGIN_ROUTE, USER_STORE } from 'utils/constants';
import {config} from 'utils/config';
import OneTimeLogin from './OneTimeLogin';

interface HomeProps {
    path: string;
}

export default function Home({path}: HomeProps) {
    useEffect(() => {
        if (localStorage.getItem(USER_STORE)) {
            return;
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Accept': 'text/plain',
                       'Content-Type': 'application/json-patch+json' }
        };
        fetch(config.get().apiBaseURL + LOGIN_TYPE_ROUTE, requestOptions)
        .then(response => response.json())
        .then(data => {
            let loginRoute=null;
            if (data.loginType === 'sso' ||
            (window.location.search.includes('code') && window.location.search.includes('openid'))) {
                loginRoute = SSO_LOGIN_ROUTE;
            } else if (data.loginType === 'kids') {
                loginRoute = KIDS_LOGIN_ROUTE;
            }
            window.location.href = loginRoute + window.location.search;
        })
        .catch(error => {
            console.log('Error fetching login type from API: ' + error);
            window.location.href = KIDS_LOGIN_ROUTE;
        });
    });
    let autoNavigate: boolean = JSON.parse(localStorage.getItem(FEATURES_STORE) || "{}").directToClassic === true;
    if (localStorage.getItem(USER_STORE)) {
        return (<div>
                    <p/>
                    <p/>
                    <OneTimeLogin  urlToGetOneTimeLogin={config.get().apiBaseURL + KIDS_LOGIN_URL_ROUTE}
                        loginInProgressMessage={LOGGING_IN_TO_KIDS_MSG}
                        loginLinkText='Go to KIDSTech'
                        autoNavigateToKids={autoNavigate}
                        kidsLoginRoute = {path} />
                </div>
            )
        }
        return null;
}
