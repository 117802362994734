import React from "react";
import { HOME_ROUTE, ERROR_MSG } from "utils/constants";

interface ErrorProps {
  path: string;
}

const Error = ({path}: ErrorProps) => {
  return (
    <div>
      {ERROR_MSG}
      <p> <a href={HOME_ROUTE}> Go back to Home Page</a></p>
    </div>
  );
};

export default Error;
