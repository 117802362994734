import React, { useState, useEffect } from 'react';
import './App.css';
import { fetchApi } from 'utils/fetch-api';
import {config} from 'utils/config';
import { FormContext } from './FormContext';
import FormElement from './FormElement';
import { SETTINGS_ROUTE } from 'utils/constants';
import ProcessKids from './ProcessKids';
import { Box, Button, Container, CssBaseline, FormControl, Grid, makeStyles, Typography } from '@material-ui/core';

interface SettingsProps {
    path: string;
}
const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));

export default function Settings(props: SettingsProps) {
    const [existingSettings, setExistingSettings] = useState<any>([]);
    const [updatedSettings] = useState<any>([]);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState<any>('Update Settings');
    const [errorMsg, setErrorMsg] = useState<any>('');

    const handleSubmit = () => {
        const settingsJSON = [];
        setBtnDisabled(true);
        setBtnText('Saving');
        for (const setting in updatedSettings) {
            settingsJSON.push({'name': setting, 'value': updatedSettings[setting]});
        }

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(settingsJSON)
        };

        fetchApi(config.get().apiBaseURL + SETTINGS_ROUTE, requestOptions,
        (_data: any)=> {
            setBtnDisabled(false);
            setBtnText('Update Settings');
        },
        ()=> {
            setErrorMsg('An error occurred while updating the settings.');
            return Promise.resolve();
        });
    };

    useEffect(() => {
        fetchApi(config.get().apiBaseURL + SETTINGS_ROUTE, { method: 'GET' }, (data: any)=> {
            setExistingSettings(data);
        })
    }, [])

    const classes = useStyles();

    if (existingSettings && existingSettings.length > 0) {
        return (
            <Container component='main' maxWidth='md'>
                <CssBaseline />
                <FormControl>
                    <FormContext.Provider value={{updatedSettings}}>
                        <div className={classes.paper}>
                            <Typography variant='h2'>
                                Update Settings
                            </Typography>
                            <Box mt={3}>
                                <Typography variant='h6' className='error'>{errorMsg}</Typography>
                            </Box>
                            <br></br>
                            <form className={classes.form} onSubmit={handleSubmit}>
                                {existingSettings.map((setting:any) => <div key={setting.label}><FormElement {...setting} /><p></p></div>)}
                                <Grid container spacing={3} justifyContent='center'>
                                    <Grid item lg={12} >
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            className={classes.submit}
                                            disabled={btnDisabled}
                                            onClick={() => handleSubmit()}
                                            >
                                            {btnText}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                            <ProcessKids></ProcessKids>
                        </div>
                    </FormContext.Provider>
                </FormControl>
            </Container>
        );
    } else {
        return (<Typography>No Settings Available</Typography>);
    }
}
