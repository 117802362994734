import React, { useState, useEffect } from 'react';
import './App.css';
import { User } from 'oidc-client';
import {config} from 'utils/config';
import { navigate } from '@reach/router';
import { getUser } from 'services/sso-auth-service';
import { LOGGING_IN_TO_KIDS_MSG , LOGIN_SSO_ERROR_MSG, LOGOUT_REDIRECT_TO_SSO_SERVER as LOGOUT_REDIRECT_TO_SSO_MSG, LOGOUT_ROUTE } from 'utils/constants';

interface LoginProps {
    userManager: any;
}

function gotoURL(data: any) {
    if (typeof data !== 'undefined' && typeof data.loginURL !== 'undefined') {
        window.location = data.loginURL;
    }
}

export default function Login(props: LoginProps) {
    const [errorMessage, setErrorMessage] = useState('');
    const [redirectMessage, setRedirectMessage] = useState(LOGGING_IN_TO_KIDS_MSG);

    function handleError(response: Response) {
        console.log(response)
        if (response.ok) {
            return response.json();
        }

        response.json().then((data)=> {
            if (typeof data === 'undefined' || typeof data[0].message == 'undefined') {
                setErrorMessage(LOGIN_SSO_ERROR_MSG);
            } else {
                setErrorMessage(data[0].message);
            }
            setRedirectMessage(LOGOUT_REDIRECT_TO_SSO_MSG);
        });

        setTimeout(() => navigate(LOGOUT_ROUTE), 10000);
    }

    useEffect(() => {
        getUser().then((user: User | null) => {
            var userParam = user == null ? '' : '?user=' + user.profile.email;
            var url = config.get().apiBaseURL + '/identity' + userParam;
            var accessToken = user == null ? '' : user.access_token;
            const requestOptions = {
                method: 'GET',
                headers: { 'Authorization': 'Bearer ' + accessToken }
            };
            fetch(url, requestOptions)
                .then(handleError)
                .then(gotoURL)
                .catch(error => {
                    console.log(error);
                    setErrorMessage(LOGIN_SSO_ERROR_MSG);
                    setTimeout(() => navigate(LOGOUT_ROUTE), 5000);
            });
        });
    }, [errorMessage]);

    return (
        <div>
            <div className='error'> {errorMessage} </div>
            <div> {redirectMessage} </div>
        </div>
    )
}
