import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    banner: {
        height: '7em',
        padding: '10px'
    }
});

export default function Banner() {
    const bannerImage= require('images/banner' + (Math.floor(Math.random() * 6) + 1) + '.png');
    const bannerImgURL = typeof(bannerImage) == 'object' ? bannerImage.default : bannerImage;
    const classes = useStyles();

    return (
        <img src={bannerImgURL} alt='KIDSTech_banner_image' className={classes.banner}/>
    );
} 
