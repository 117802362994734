import React, { useEffect, useState } from 'react';
import { Box, Button, Link } from '@material-ui/core';
import Login from './Login';
import { REDIRECTING_TO_SSO_LOGIN } from 'utils/constants';
import { signinRedirect, signoutRedirect } from 'services/sso-auth-service';

interface SSOLoginProps {
    userManager: any;
    path: string;
}

export default function SSOLogin({ userManager }: SSOLoginProps) {
    const [message, setMessage] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(()=> {
        userManager.events.addUserLoaded(() => {
            setIsAuthenticated(true);
        });
        userManager.events.addUserUnloaded(() => {
            setIsAuthenticated(false);
        });
        userManager.getUser().then((user: any) => {
            if (user == null || user === undefined) {
                userManager.signinRedirectCallback().then(() => {
                    window.history.replaceState({}, '', '/');
                }).catch((err: any) => {
                    console.log('Error signinRedirectCallback: ', err);
                });
            }
        });
    });

    function login() {
        setMessage(REDIRECTING_TO_SSO_LOGIN);
        signinRedirect();
    }

    if (isAuthenticated) {
        return <Login userManager={userManager} />
    } 
    if (window.location.search !== '' && message !== '') {
        signoutRedirect();
    }
    return (
        <div>
            <Box pt={5}>
                <Button variant='contained' color='primary' onClick={() => login()}>
                    Login via ISBE Single Sign-on
                </Button>
            </Box>
            {message !== '' && <p>{message}</p> }
            <Box pt={3} pb={5}>
                <Link href='https://www.isbe.net/Documents/KIDStech-Login-Instruction.pdf' target='_blank' rel='noopener'>
                    View Single Sign-on Instructions
                </Link>
            </Box>
        </div>
    );
}
