import React, { useEffect } from 'react';
import { UserManager } from 'oidc-client';
import { authenticationService } from 'services/authentication-service';
import { navigate } from '@reach/router';
import { HOME_ROUTE, SSO_LOGOUT_MSG, USER_STORE } from 'utils/constants';

interface LogoutProps {
  userManager: UserManager;
  path: string;
}

export default function SSOLogout(props: LogoutProps) {
    useEffect(() => {
      props.userManager.getUser().then(function (user) {
          if (user !== null && user !== undefined) {
            props.userManager.signoutRedirect();
            authenticationService.logout();
            return;
          }
          authenticationService.logout();
          navigate(HOME_ROUTE);
      });
    });
    return (
        <div> {SSO_LOGOUT_MSG} </div>
    )
}
