export interface Endpoint {
  path: string;
  httpMethod: string;
}

export interface Features {
  endpoints: Array<Endpoint>;
  directToClassic: boolean;
}

export class MenuItem {
  endpointPath: string;
  route: string;
  name: string;
  constructor(endpointPath: string, route: string, name: string) {
    this.endpointPath = endpointPath;
    this.route= route;
    this.name = name;
  }
}
