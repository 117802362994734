var OidcSettings = {
    authority:"",
    client_id: "drdptech",
    redirect_uri: "",
    response_type: "code",
    scope: "openid profile",
    post_logout_redirect_uri: "",
}

export default OidcSettings;

