import OidcSettings from './oidcsettings';
import { UserManager } from 'oidc-client';
import {config} from 'utils/config';
import { HOME_ROUTE, LOGOUT_CALLBACK_ROUTE } from 'utils/constants';

OidcSettings.redirect_uri = window.location.origin + HOME_ROUTE;
OidcSettings.post_logout_redirect_uri = window.location.origin + LOGOUT_CALLBACK_ROUTE
const userManager = new UserManager(OidcSettings);

export function initSSOAuthority() {
    userManager.settings.authority = config.get().identityServerBaseURL;
}
export function signinRedirect() {
    return userManager.signinRedirect()
}

export function signinRedirectCallback() {
    return userManager.signinRedirectCallback()
}

export function signoutRedirect() {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirectCallback()
}
export function getUser() {
    return userManager.getUser();
}

export default userManager;
