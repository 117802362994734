import React, { useState, useEffect} from 'react';
import './App.css';
import { authHeader } from 'utils/fetch-api';
import {handleLoginError, logout} from 'services/authentication-service';
import { navigate } from '@reach/router';
import { USER_STORE } from 'utils/constants';
import { Box, Button } from '@material-ui/core';

interface OneTimeLoginProps {
    urlToGetOneTimeLogin: string;
    loginInProgressMessage: string;
    loginLinkText: string;
    autoNavigateToKids: boolean;
    kidsLoginRoute: string;
}

const OneTimeLogin = (props: OneTimeLoginProps) => {
    const [message, setMessage] = useState("");

    const gotoURL= (data: any) => {
        if (typeof data !== 'undefined' && typeof data.loginURL !== 'undefined') {
            window.location = data.loginURL;
          }  
    }

    const doLoginFailed = () => {
        logout();
        setMessage('Login failed, please try again.');
        setTimeout(()=> navigate(props.kidsLoginRoute), 2000);
    }
    
    const doOneTimeLogin = () => {
        setMessage(props.loginInProgressMessage);
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };
        fetch(props.urlToGetOneTimeLogin, requestOptions)
        .then(handleLoginError)
        .then(gotoURL)
        .catch(error => {
            setTimeout(()=> doLoginFailed(), 3000);
        });
    }
   
    useEffect(() => {
       if (props.autoNavigateToKids && localStorage.getItem(USER_STORE) != null) {
        doOneTimeLogin();
       }
    });
    
    return (
        <div>
            {!props.autoNavigateToKids &&
                <Box pt={5}>
                    <Button variant='contained' color='secondary' onClick={doOneTimeLogin}>
                        {props.loginLinkText}
                    </Button>
                </Box>
            }
            {message !== '' && <p>{message}</p> }    
        </div>
   );
}

export default OneTimeLogin;
