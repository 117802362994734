import React, { useState } from 'react';
import './App.css';
import { fetchApi } from 'utils/fetch-api';
import {config} from 'utils/config';
import { PROCESS_KIDS_ROUTE } from 'utils/constants';
import { Box, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
}));

export default function ProcessKids() {
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState<any>('Process Kids');
    const [errorMsg, setErrorMsg] = useState<any>('');
    const classes = useStyles();
    const processKids = () => {
        setBtnDisabled(true);
        setBtnText('Processing Kids');

        fetchApi(config.get().apiBaseURL + PROCESS_KIDS_ROUTE, { method: 'POST' }, 
        (_data: any)=> {
            setBtnDisabled(false);
            setBtnText('Process Kids');
        },
        ()=> {
            setErrorMsg('An error occurred while processing kids.');
            return Promise.resolve();
        })
    }

    return (
        <Box>
            <p>{errorMsg}</p>
            <Button
                fullWidth
                variant='contained'
                color='secondary'
                className={classes.submit}
                disabled={btnDisabled}
                onClick={() => processKids()}
            >
                {btnText}
            </Button>
        </Box>
    );
}
