import React, { useState, useEffect } from 'react';
import './App.css';
import { DataGrid, GridColDef, GridRowModel, GridEditCellPropsParams, GridRowData } from '@material-ui/data-grid';
import { fetchApi } from 'utils/fetch-api';
import { Box, Button, Container, CssBaseline, makeStyles, Typography } from '@material-ui/core';

interface DeactivateUsersProps {
    path: string;
    endpointURL: string;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    table: {
        minWidth: 800,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const initialRows: GridRowModel[] = 
    Array.from({ length: 10 }, (_, index) => ({
        id: index + 1, iein: null, sourceUserId: null, targetUserId: null
    }));

export default function DeactivateUsers({ endpointURL }: DeactivateUsersProps) {
    const [message] = useState('');
    const [showStatusCol, setShowStatusCol] = useState<any>(false);
    const [rows, setRows] = useState(initialRows);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState<any>('Deactivate Users');
    const [errorMsg, setErrorMsg] = useState<any>('');

    const classes = useStyles();

    const columns: GridColDef[] = [
        { headerName: 'IEIN', field: 'iein', width: 160, editable: true },
        { headerName: 'Previous User ID/Email', field: 'sourceUserId', flex: 1, editable: true },
        { headerName: 'New User ID/Email', field: 'targetUserId', flex: 1, editable: true },
    ];

    if (showStatusCol) {
        columns.push({
            headerName: 'Status',
            field: 'status',
            flex: 1,
            editable: false,
            cellClassName: (params) => {
                const isRowValid = params.row.valid;
                return isRowValid ? 'success' : 'error';
            },
        });
    }

    const isCellEditable = (params:any) => {
        return !params.row.valid;
    };

    const handleCellEditCommit = (params: GridEditCellPropsParams) => {
        const updatedRows = rows.map((row) => {
            if (row.id === params.id) {
                return { ...row, [params.field]: params.props.value };
            }
            return row;
        });
        setRows(updatedRows);
    };

    const clearForm = () => {
        setRows(initialRows);
        setShowStatusCol(false);
    }

    const validateRowData = (rows:GridRowData[]) => {
        var isValid = true;
        rows.map((row) => {
            if (!row.iein) {
                setErrorMsg((prevErrorMsg:string) => prevErrorMsg + '\nRow ' + row.id + ": IEIN is required")
                isValid = false;
            }
            if (row.sourceUserId === row.targetUserId) {
                setErrorMsg((prevErrorMsg:string) => prevErrorMsg + '\nRow ' + row.id + ": Previous User ID/Email cannot have the same value as New User ID/Email")
                isValid = false;
            }
        });
        return isValid;
    };

    const handleSubmit = () => {
        setErrorMsg('');

        const rowsWithValues = rows.filter((row) => {
            const { id, ...rest } = row;
            return Object.values(rest).some((value) => value !== null && value !== '');
        });

        if (!validateRowData(rowsWithValues)) {
            return;
        }

        const filteredRows = rowsWithValues.filter((row) => {
            // Ignore rows where row.valid is 'true'
            return row.valid !== 'true';
        });

        setBtnDisabled(true);
        setBtnText('Processing');
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(filteredRows)
        };

        fetchApi(endpointURL, requestOptions,
        (_data: any)=> {
            setBtnDisabled(false);
            setBtnText('Deactivate Users');
            setShowStatusCol(true);
            addResponseDataToRows(_data);
        },
        ()=> {
            setErrorMsg('An error occurred while deactivating the users.');
            return Promise.resolve();
        });
    };

    const addResponseDataToRows = (data:any) => {

        // Combine status messages
        const combinedData = data.reduce((result:any, current:any) => {
            const existingItem = result.find((item:any) => item.iein === current.iein);
            if (existingItem) {
                existingItem.status += `, ${current.status}`;
            } else {
                result.push({ ...current });
            }
            return result;
        }, []);

        // Add status and valid attributes to existing rows
        const updatedRows = rows.map(row => {
            const existingRow = combinedData.find((item:any) => item.iein === row.iein);
            if (existingRow) {
              return {
                ...row,
                status: existingRow.status,
                valid: existingRow.valid,
              };
            }
            return row;
          });
        setRows(updatedRows);
    }

    return (
        <Container component='main' maxWidth='xl'>
            <CssBaseline />
            <div className={classes.paper}>
                <Typography variant='h2'>
                    Deactivate Users
                </Typography>
                <Box mt={3}>
                    <Typography variant='h6' className='error'>{errorMsg}</Typography>
                </Box>
            </div>
            { !message &&
                <Box mt={5}>
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            disableSelectionOnClick
                            autoHeight
                            autoPageSize
                            onEditCellChangeCommitted={handleCellEditCommit}
                            isCellEditable={isCellEditable}
                        />
                    </div>
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        disabled={btnDisabled}
                        onClick={handleSubmit}
                        >
                        {btnText}
                    </Button>
                    <br></br>
                    <Button
                        variant='contained'
                        color='secondary'
                        className={classes.submit}
                        onClick={clearForm}
                        >
                        Clear Form
                    </Button>
                </Box>
            }
            <div>{message}</div>
        </Container>
    );
}
