import { User } from 'oidc-client';
import { authenticationService, getCurrentUser } from 'services/authentication-service';
import { getUser } from 'services/sso-auth-service';
import { navigate } from '@reach/router';
import { ERROR_ROUTE } from './constants';

export const defaultHandleError = (response: Response, data: any) => {
    if ([401, 403].indexOf(response.status) !== -1) {
        navigate(ERROR_ROUTE);
        return;
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
}

export function getPrepResponseHandler (handleError?: (response: Response, data: any) => Promise<any>) {
    let doHandleError = typeof handleError === 'undefined' ? defaultHandleError: handleError
    return (response: Response) => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                doHandleError(response, data)
            }

            return data;
        });
    }
}

export function authHeader() {
    let currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return bearerHeader(currentUser.token);
    }
    currentUser = getUser();
    return bearerHeader(currentUser.access_token);
}

export function bearerHeader(token: string) {
    // return authorization header with jwt token
    var header: any= {'Content-Type': 'application/json'}
    if (token) {
        header.Authorization = `Bearer ${token}`;
    }  
    return header;
}

export function fetchApi(url :string , requestOptions :any,
    handleResponseData : (value : any) => any ,
    handleError?: (response: Response, data: any) => Promise<any>,
    authUserFetchOnly: boolean = false ) {
        
    getCurrentUser().then( (user: User | null) => {
        let authRequestOptions= {...requestOptions};
        if (authUserFetchOnly && user == null) {
            return;
        }
        if (user != null) {
            authRequestOptions.headers = bearerHeader(user.access_token);
        }
        fetch(url, authRequestOptions)
        .then(getPrepResponseHandler(handleError))
        .then(handleResponseData)
        .catch((error) => {
          console.log(error);
        });
    });
      
}
