import { createTheme } from '@material-ui/core/styles';

export const kidstechTheme = createTheme({
    palette: {
      primary: {
        light: '#80b5ca',
        main: '#61A3BD',
        dark: '#437284',
        contrastText: "#fff"
      },
      secondary: {
        light: '#a7c364',
        main: '#92B43E',
        dark: '#667d2b',
        contrastText: "#fff"
      }
    },
    typography: {
      subtitle1: {
        fontSize: 12,
      }
    }
});
