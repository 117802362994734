import React from 'react';
import SelectField from './SelectField';
import NumberField from './NumberField';
import TextInputField from './TextInputField';
import BoolField from './BoolField';
import { Grid, Typography } from '@material-ui/core';

interface FormElementProps {
    label: string;
    name: string;
    value: string;
    minValue: number;
    maxValue: number;
    possibleValues: string[];
    type: string;
}

export default function FormElement(props: FormElementProps) {
    if(props.possibleValues)
        return <SelectField {...props} />
    else
        switch(props.type) {
            case 'bool':
                return <BoolField {...props} possibleValues={['true', 'false']} />
            case 'string':
                return <TextInputField {...props} />
            case 'int':
            case 'float':
                return <NumberField {...props} />
            default:
                return (
                    <Grid container spacing={3} justifyContent='center'>
                        <Grid item lg={12}>
                            <Typography>{props.label}<span className='error'>*</span></Typography>
                            <Typography variant='subtitle1'><span className='error'>*</span>Setting is not valid and/or cannot be edited</Typography>
                        </Grid>
                    </Grid>
                );
        }
}
