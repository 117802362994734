export const ISBE_LINK :string = 'Illinois State Board of Education';
export const FORGOT_PASSWORD_REQUEST_SENT_MSG :string = 'Your request for resetting password was sent. You should receive an email about it soon';
export const LOGGING_IN_TO_KIDS_MSG : string = "You are being logged into KIDSTech..." ;
export const LOGIN_SSO_ERROR_MSG :string = 'There was an error with your sign-in, please contact the site administrator.';
export const LOGOUT_REDIRECT_TO_SSO_SERVER :string = 'You are being logged out and directed to the ISBE single sign-on page.';
export const REDIRECTING_TO_SSO_LOGIN :string = 'You are being directed to the ISBE single sign-on page'
export const ERROR_MSG :string = 'Page you requested is unavailable or an error occurred, please contact the site administrator.';
export const SSO_LOGOUT_MSG :string = 'You are being logged out...';

// React Routes
export const FORGOT_PASSWORD_ROUTE = '/forgotpassword';
export const GO_TO_OLD_KIDSTECH_ROUTE = '/gotokidstech';
export const HOME_ROUTE : string= '/';
export const KIDS_LOGIN_ROUTE = '/kidslogin';
export const KIDS_STATUS_MSG_ROUTE = '/kids/status/messages';
export const LOGOUT_CALLBACK_ROUTE = '/logoutcallback';
export const SSO_LOGIN_ROUTE = '/ssologin';
export const ERROR_ROUTE = '/unavailable';
export const LOGOUT_ROUTE = '/logout';
export const LOGIN_TYPE_ROUTE = '/logintype';
export const LOGIN_ROUTE = '/login';
export const KIDS_LOGIN_URL_ROUTE='/kidsloginurl';
export const SETTINGS_ROUTE = '/settings';
export const FEATURES_ROUTE = '/features';
export const PROCESS_KIDS_ROUTE = '/processkids';
export const LOGIN_ISSUES_ROUTE = '/login-issues';
export const DEACTIVATE_USERS_ROUTE = '/deactivate-users';

// Local Storage Keys
export const FEATURES_STORE = 'features';
export const USER_STORE = 'currentUser';
