import { AppBar, Button, Box, Typography } from '@material-ui/core';
import { Features, MenuItem } from 'models/models';
import React from 'react';
import { USER_STORE , HOME_ROUTE, KIDS_STATUS_MSG_ROUTE, LOGOUT_ROUTE } from 'utils/constants';

const MENU_MAP: any = {
    [KIDS_STATUS_MSG_ROUTE]: new MenuItem(KIDS_STATUS_MSG_ROUTE, KIDS_STATUS_MSG_ROUTE, 'Kids Statuses'),
    [HOME_ROUTE]: new MenuItem(HOME_ROUTE,HOME_ROUTE,'Home')
}

const menuItem= (menuItemObj : any) => {
    return (
        <Box key={menuItemObj.route} ml={3} >
            <Button href={menuItemObj.route} size='large' color='inherit'>
                <Typography variant='h6'>
                    {menuItemObj.name}
                </Typography>
            </Button>
        </Box>

    );
}

interface NavigationProps {
    features: Features
}

export default function Navigation({features} : NavigationProps) {
    const LOGOUT_LABEL:string = 'Logout'; 
  
    let menuButtons = [menuItem(MENU_MAP[HOME_ROUTE])]
    for (const element of features.endpoints) {
        if (element.httpMethod === 'GET' && element.path in MENU_MAP) {
            menuButtons.push(menuItem(MENU_MAP[element.path]))
        }
    }

    return (
        <AppBar position='static'>
            <Box display='flex'>
                {menuButtons}  
                {
                    localStorage.getItem(USER_STORE) &&
                    <Box key={LOGOUT_ROUTE} ml={3}>
                        <Button key={LOGOUT_ROUTE} href={localStorage.getItem(USER_STORE)? LOGOUT_ROUTE : HOME_ROUTE} size='large' color='inherit'>
                            <Typography variant='h6'>
                            {LOGOUT_LABEL}
                            </Typography>
                        </Button>
                    </Box>
                }
            </Box>
        </AppBar>
    );
}
