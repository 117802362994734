import React, { useEffect, useState } from 'react'
import { navigate, RouteComponentProps, Router } from '@reach/router';
import './App.css';
import IdleTimer from 'services/IdleTimer';
import { authenticationService } from 'services/authentication-service';
import { ThemeProvider } from '@material-ui/core/styles';

// React Components
import Footer from './Footer';
import ForgotPassword from './ForgotPassword';
import Header from './Header';
import Home from './Home';
import KIDSTechLogin from './KIDSTechLogin';
import Settings from './Settings';
import SSOLogin from './SSOLogin';
import SSOLogout from './SSOLogout';
import LoginIssues from './LoginIssues';
import DeactivateUsers from './DeactivateUsers';
import {config} from 'utils/config';

import { ERROR_ROUTE, FORGOT_PASSWORD_ROUTE, HOME_ROUTE, KIDS_LOGIN_ROUTE, 
  KIDS_STATUS_MSG_ROUTE, LOGOUT_CALLBACK_ROUTE, SETTINGS_ROUTE,
  SSO_LOGIN_ROUTE, LOGOUT_ROUTE, FEATURES_STORE, FEATURES_ROUTE, USER_STORE, LOGIN_ISSUES_ROUTE, DEACTIVATE_USERS_ROUTE } from 'utils/constants';
import userManager from 'services/sso-auth-service';
import KidsStatusMessages from './KidsStatusMessages';

import Error from 'components/Error';
import Navigation from './Navigation';
import { Features } from 'models/models';
import { fetchApi } from 'utils/fetch-api';
import { kidstechTheme } from 'utils/kidstechTheme';

export default function App() {
  const [features, setFeatures] = useState<Features>({endpoints: [], directToClassic: false});

    const loadFeatures = () => {
      if (features.endpoints.length > 0 || ! localStorage.getItem(USER_STORE))  {
        return;
      } 
      if (localStorage.getItem(FEATURES_STORE) ) {
            setFeatures(JSON.parse(localStorage.getItem(FEATURES_STORE) || '{endpoints: [], directToClassic: false}'))
        } else if (localStorage.getItem(USER_STORE)){
        fetchApi(config.get().apiBaseURL + FEATURES_ROUTE, {method: 'GET'}, 
        (data: Features)=> {
            console.log(data);
            localStorage.setItem(FEATURES_STORE, JSON.stringify(data));
            setFeatures(data);
            },
        ()=> {
            setFeatures({endpoints: [], directToClassic: false});
            return Promise.resolve();
        }, true );
        }
    }
    
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: config.get().loginTimeout,
      onTimeout: () => {
        authenticationService.logout();
        navigate(HOME_ROUTE);
      },
      onExpired: () => {
        // authenticationService.logout();
      }
    });
    loadFeatures();
    return () => {
      timer.cleanUp();
    };
  });

  return (
    <ThemeProvider theme={kidstechTheme}>
      <div className='App'>
        <Header />
        <nav>
          <Navigation features={features}/>
        </nav>
        <p />
        <Router>
          <KIDSTechLogin
            path={KIDS_LOGIN_ROUTE}
            onLoginChange={loadFeatures}
          />
          <Home
            path={HOME_ROUTE}
          />
          <SSOLogin
            path={SSO_LOGIN_ROUTE}
            userManager={userManager}
          />
          <ForgotPassword
            path={FORGOT_PASSWORD_ROUTE}
          />
          <SSOLogout
            path={LOGOUT_ROUTE}
            userManager={userManager}
          />
          <Home
            path={LOGOUT_CALLBACK_ROUTE}
          />
          <KidsStatusMessages
          path={KIDS_STATUS_MSG_ROUTE}
          baseURL = {config.get().apiBaseURL}
          />
          <Settings
            path={SETTINGS_ROUTE}
          />
          <LoginIssues
            path={LOGIN_ISSUES_ROUTE}
            endpointURL = {config.get().apiBaseURL + LOGIN_ISSUES_ROUTE}
          />
          <DeactivateUsers
            path={DEACTIVATE_USERS_ROUTE}
            endpointURL = {config.get().apiBaseURL + DEACTIVATE_USERS_ROUTE}
          />
          <Error path={ERROR_ROUTE} />
          <NotFound default />
        </Router>
        <p />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

const NotFound = (props: RouteComponentProps) =><><p>Sorry, page you are looking for is not found.</p><a href={HOME_ROUTE}> Go back to Home Page</a></>
