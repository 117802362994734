import { navigate } from "@reach/router";
import { User } from "oidc-client";
import { FEATURES_STORE, HOME_ROUTE, USER_STORE } from "utils/constants";
import { getUser } from "./sso-auth-service";

const rxjs = require('rxjs');

const currentUserSubject = getCurrentUserSubject();

export interface KidsUser {
    userId : string,
    token: string
}
export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function getCurrentUserSubject() {
    const subject = JSON.parse(localStorage.getItem(USER_STORE) || "{}") 

    return new rxjs.BehaviorSubject(subject.userId? subject : null);
}

export function getCurrentUser() : Promise<User | null> {
    let kidLoginUser= JSON.parse(localStorage.getItem(USER_STORE) || "{}")
    if ( kidLoginUser !== null && typeof(kidLoginUser.userId) !== 'undefined' 
        && typeof(kidLoginUser.token) !== 'undefined') {
        return new Promise((resolve, reject) => {
            resolve({id_token: kidLoginUser.userId, access_token: kidLoginUser.token} as User);
        });
    }
    return getUser();
}



function login(username : string , password : string, authUrl: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({userId: username, password: password})
    };

    return fetch(`${authUrl}/`, requestOptions)
        .then(handleLoginError)
        .then(user => {
            console.log(user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(USER_STORE, JSON.stringify(user));
            currentUserSubject.next(user);
            navigate(HOME_ROUTE)
            return user;
        })
        .catch(error => {
            console.log(error);
          });
}

export function logout() {
    // remove user from local storage to log user out
    if (localStorage.getItem(USER_STORE) != null) {
        localStorage.removeItem(USER_STORE);
        localStorage.removeItem(FEATURES_STORE)
        currentUserSubject.next(null);
    }
}

export function handleLoginError(response: Response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        console.log("response.status: " + response.status);
        if (!response.ok) {
            authenticationService.logout();

            const error = (data && data.message) || response.statusText;
            console.log("Error: " + response.status);
            return Promise.reject(error);
        }

        return data;
    });
}
