import React, { useContext, useState } from 'react'
import { FormContext } from './FormContext';
import { FormControlLabel, Grid, Switch } from '@material-ui/core';

interface BoolFieldProps {
    label: string;
    name: string;
    value: string;
    possibleValues: string[];
    type: string;
}

export default function BoolField(props: BoolFieldProps) {
    const [value, setValue] = useState(props.value);
    const { updatedSettings } = useContext(FormContext);

    const handleChangeInput = (name: any, event: any) => {
        setValue(event.target.checked.toString());
        updatedSettings[name] = event.target.checked.toString();
    }

    return (
        <Grid container spacing={3} justifyContent='center'>
            <Grid item lg={12}>
                <FormControlLabel
                    label={props.label}
                    control={<Switch
                                checked={value === 'true'}
                                color='primary'
                                onChange={event => handleChangeInput(props.name, event)}
                            />}
                />
            </Grid>
        </Grid>
    );
}
