export interface DynamicConfig {
    apiBaseURL: string;
    drdpBaseURL: string;
    identityServerBaseURL: string;
    loginTimeout: number;
  }
  
  export const defaultConfig: DynamicConfig = {
    identityServerBaseURL: "https://signin.isbe.net",
    apiBaseURL: "",
    drdpBaseURL: "",
    loginTimeout: 1800
  };

class Config {
    config: DynamicConfig = defaultConfig; // assign a value because of TypeScript
    notDefinedYet = true;
  
    public get(): DynamicConfig {
      if (this.notDefinedYet) {
        throw new Error("Config has not been defined yet.  Be sure to call the getter only after the config has been downloaded and set. Probable cause is accessing config in static context.");
      } else {
        return this.config;
      }
    }
  
    public set(value: DynamicConfig): void {
      if (this.notDefinedYet) {
        this.config = value;
        this.notDefinedYet = false;
      } else {
        throw new Error("Config has already been defined and now has been called second time. This is probably not intended.");
      }
    }
  }
  
  export const config = new Config();
  export const configUrl = "/config.json"; 

