import React, { useContext, useState } from 'react'
import { FormContext } from './FormContext';
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'

interface SelectFieldProps {
    label: string;
    name: string;
    value: string;
    possibleValues: string[];
    type: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    }
}));

export default function SelectField(props: SelectFieldProps) {
    const [value, setValue] = useState(props.value);
    const { updatedSettings } = useContext(FormContext);
    const classes = useStyles();
    const handleChangeInput = (name: any, event: any) => {
        setValue(event.target.value);
        updatedSettings[name] = event.target.value;
    }

    return (
        <Grid container spacing={3} justifyContent='center'>
            <Grid item lg={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel id={props.name + '-label'}>{props.label}</InputLabel>
                    <Select
                        labelId={props.name + '-label'}
                        value={value}
                        onChange={event => handleChangeInput(props.name, event)}
                    >
                        {
                            props.possibleValues.length > 0 && props.possibleValues.map((option, i) => {
                                return <MenuItem key={option} value={option}>{option}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
