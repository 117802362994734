import React, { useState, useEffect } from 'react';
import './App.css';
import OneTimeLogin from 'components/OneTimeLogin';
import { Link, navigate } from '@reach/router';
import { authenticationService } from 'services/authentication-service';
import {
  FORGOT_PASSWORD_ROUTE,
  KIDS_LOGIN_URL_ROUTE,
  LOGGING_IN_TO_KIDS_MSG,
  LOGIN_ROUTE,
  LOGIN_TYPE_ROUTE,
  USER_STORE,
  SSO_LOGIN_ROUTE,
  HOME_ROUTE,
  FEATURES_STORE
} from 'utils/constants';
import {config} from 'utils/config';
import { Box, Button, Container, CssBaseline, Grid, makeStyles, TextField, Typography } from '@material-ui/core';

interface KIDSTechLoginProps {
  path: string;
  onLoginChange: Function;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function KIDSTechLogin({path, onLoginChange}: KIDSTechLoginProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loginTypeMessage, setLoginTypeMessage] = useState('');

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }
 
  useEffect(()=> {
    setEmail('');
    setPassword('');
    fetch(config.get().apiBaseURL + LOGIN_TYPE_ROUTE, {
        method: 'GET',
        headers: { 'Accept': 'text/plain',
                   'Content-Type': 'application/json-patch+json' }
      })
      .then(response => response.json())
      .then(
        (data) => {
          if (data.loginType === 'sso') {
            setLoginTypeMessage('This login page is disabled. Please use Single Sign-on to log in ');
          }
        })
      .catch((error) => {
        console.log('Error fetching login type from API: ' + error);
      }
      )
  }, [loginTypeMessage, message]);

  function handleSubmit(event: any) {
    event.preventDefault();
    var loginEmail = email;
    var loginPassword = password
    setEmail('');
    setPassword('');
    authenticationService.login(loginEmail,loginPassword, config.get().apiBaseURL + LOGIN_ROUTE)
    .then(user => {
      if (user !== null && user !== undefined) {
        onLoginChange();
        navigate(HOME_ROUTE,{replace: true});
      } else {
        setMessage('Invalid login, please try again.');
      }
    })
    .catch( error => {
      console.log(error);
    });
  }

  const classes = useStyles();

  if (localStorage.getItem(USER_STORE)) {
    navigate(HOME_ROUTE);
    return null;
  } 

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      {
        !localStorage.getItem(USER_STORE) &&
        <div className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography className='error' >{message}</Typography>
            {
              loginTypeMessage !== '' &&
              <Box pb={3}>
                <Typography className='error'>
                  <>{loginTypeMessage}<Link to={SSO_LOGIN_ROUTE}>here</Link>{'.'}</>
                </Typography>
              </Box>
            }
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Username or Email'
              name='email'
              autoFocus
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={!validateForm}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Typography variant='body2'>
                  <Link to={FORGOT_PASSWORD_ROUTE}>
                    Forgot password?
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
      }
    </Container>
  );
}
