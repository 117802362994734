import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'components/App';
import {config, configUrl} from 'utils/config'
import { getPrepResponseHandler } from 'utils/fetch-api';
import { initSSOAuthority } from 'services/sso-auth-service';

console.log(configUrl);
let header: any= {'Content-Type': 'application/json'}
fetch(configUrl, header)
.then(getPrepResponseHandler()) 
.then((data)=> {
    config.set(data);
    initSSOAuthority();
    return <App/>
  })
  .catch((e) => {
    const errorMessage =
        "Error while fetching configuration , the application will not load, please contact Administrator";
      console.error(
        errorMessage,
        `Missing config file '${configUrl}'?`,
        e
      );
      return (
        <p style={{ color: "red", textAlign: "center" }}>{errorMessage}</p>
      );
    })
  .then((reactElement: ReactElement) => {
    ReactDOM.render(
      <React.StrictMode>
        {reactElement}
       </React.StrictMode>
      ,document.getElementById('root')
    )
  });




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
