import React from 'react';
import Banner from './Banner';
import Logo from 'images/kidstech_logo.png';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    logo: {
        position: 'absolute',
        float: 'left',
        height: '88px',
        width: '156px',
        padding: '10px'
    },
    header: {
        fontFamily: 'Arial, Verdana, sans-serif',
        color: '#000080',
        fontSize: '2.05em'
    },
    copyright: {
        fontSize: '65%',
        verticalAlign: 'top'
    }
});

export default function Header() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box position='absolute'>
               <img src={Logo} alt='KIDSTech_logo' className={classes.logo}/>
            </Box>
            <Banner />
            <Box className={classes.header} mb={3}>
                Desired Results Developmental Profile<span className={classes.copyright}>©</span>
            </Box>
        </div>
    );
}
