import React, { useState, useEffect } from "react";
import "./App.css";
import { fetchApi } from "utils/fetch-api";
import { DataGrid} from "@material-ui/data-grid";
import Tooltip from "@material-ui/core/Tooltip";
import SearchBar from "material-ui-search-bar";
import { Box, Container, CssBaseline, makeStyles, Typography } from '@material-ui/core';

interface KidsStatusMessageProps {
  path: string;
  baseURL: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  table: {
    minWidth: 650,
  }
}));

const KidsStatusMessages = ({ path, baseURL }: KidsStatusMessageProps) => {
  const [message] = useState("");
  const [data, setData] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [searched, setSearched] = useState<string>("");
  const classes = useStyles();

  const toolTip = (params: any) => (
    <Tooltip title={params.value}>
      <span>{params.value}</span>
    </Tooltip>
  );

  const columns = React.useMemo(
    () => [
      { headerName: "Student Id", field: "studentId", width: 150 },
      { headerName: "Status", field: "status", width: 120 },
      { headerName: "Type", field: "type", width: 110 },
      { headerName: "Agency", field: "agency", width: 140 },
      { headerName: "Site", field: "site", width: 180 },
      { headerName: "Rating Period", field: "term", width: 170 },
      { headerName: "RCDTS", field: "rcdts", width: 160 },
      { headerName: "Process Date", field: "createdAt", width: 170 },
      { headerName: "IEIN", field: "iein", width: 120 },
      { headerName: "Message", 
        field: "message", 
        width: 300,
        renderCell: toolTip 
      },
      {
        headerName: "Recommended Action",
        field: "recommendedAction",
        width: 400,
        renderCell: toolTip
      },
    ],
    []
  );

  function convertUTCDateToLocalDate(createdAtDate: string) : Date {
    const date = new Date(createdAtDate);
    const offset = date.getTimezoneOffset();
    const localTime = date.getTime() - offset * 60 * 1000;
    return new Date(localTime);
}

  useEffect(() => {
    
    fetchApi(baseURL + path, {method: 'GET'}, (ksm: any)=> {
      const messages = ksm.messages;
      for (let i = 0; i < messages.length; i++) {
        messages[i].id = i + 1;
        messages[i].type = ksm.statusTypes[messages[i].status].type;
        messages[i].message = ksm.statusTypes[messages[i].status].message;
        messages[i].createdAt = convertUTCDateToLocalDate(messages[i].createdAt);
        messages[i].recommendedAction = ksm.statusTypes[messages[i].status].recommendedAction;
      }
      setData(messages);
      setRows(messages);
    })
 
  },[baseURL, message, path]);

  const requestSearch = (searchedVal: string) => {
    const filteredRows = data.filter((row: any) => {
      for (const element of columns) {
        if ((row[element["field"]] + "").toLowerCase().includes(searchedVal.toLowerCase())){
          return true;
        }
      }
      return false;
    });

    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    setRows(data);
  };

  return (
    <Container component='main' maxWidth='xl'>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography variant='h2'>
          Kids Status Messages
        </Typography>
      </div>
      { !message && 
        <Box mt={5}>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            style={{width:"40%"}}
          />
          <div style={{ height: 800, width: "100%" }}>
            <DataGrid
              className={classes.table}
              rows={rows}
              columns={columns}
              pageSize={30}
            />
          </div>
        </Box>
      }
      <div>{message}</div>
    </Container>
  );
};

export default KidsStatusMessages;
