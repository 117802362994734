import React from 'react';
import { Link } from '@reach/router';
import { ISBE_LINK } from 'utils/constants';
import { Box, Typography } from '@material-ui/core';
import { config } from 'utils/config';

export default function Footer() {
    return (
        <Box mt={7} mb={4}>
            <Typography variant='body2' color='textSecondary' align='center'>
                {'Copyright © ' + new Date().getFullYear() + ' '}
                <a href={config.get().drdpBaseURL + '/Public/Copyright.aspx'} target='blank'>
                    {ISBE_LINK}
                </a>
                {' All rights reserved.'}
            </Typography>
        </Box>
    );
}
