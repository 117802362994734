import React, { useState, useEffect } from 'react';
import './App.css';
import { DataGrid } from '@material-ui/data-grid';
import { fetchApi } from 'utils/fetch-api';
import Tooltip from '@material-ui/core/Tooltip';
import SearchBar from 'material-ui-search-bar';
import { Box, Container, CssBaseline, makeStyles, Typography } from '@material-ui/core';

interface LoginIssuesProps {
    path: string;
    endpointURL: string;
}

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    table: {
      minWidth: 650,
    }
}));

export default function LoginIssues({ path, endpointURL }: LoginIssuesProps) {
    const [message] = useState('');
    const [data, setData] = useState<any>([]);
    const [rows, setRows] = useState<any>([]);
    const [searched, setSearched] = useState<string>('');
    const classes = useStyles();

    const toolTip = (params: any) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
    );

    const columns = React.useMemo(
        () => [
            { field: 'logged', headerName: 'Logged', width: 150 },
            { field: 'user_id', headerName: 'User ID', width: 150 },
            { field: 'type', headerName: 'Issue Type', width: 200, renderCell: toolTip },
            { field: 'message', headerName: 'Error Message', width: 300, renderCell: toolTip },
            { field: 'claims', headerName: 'Claims Received', width: 300, renderCell: toolTip }
        ],[]
    );

    useEffect(() => {
        fetchApi(endpointURL, {method: 'GET'}, (li: any)=> {
          const loginIssues = li;
          for (let i = 0; i < loginIssues.length; i++) {
            loginIssues[i].id = i + 1;
            loginIssues[i].logged = li[i].logged;
            loginIssues[i].user_id = li[i].userId
            loginIssues[i].type = li[i].type
            loginIssues[i].message = li[i].message
            loginIssues[i].claims = li[i].claims;
          }
          setData(loginIssues);
          setRows(loginIssues);
        })

      },[endpointURL, message, path]);

    const requestSearch = (searchedVal: string) => {
        const filteredRows = data.filter((row: any) => {
            for (const element of columns) {
                if ((row[element['field']] + '').toLowerCase().includes(searchedVal.toLowerCase())){
                    return true;
                }
            }
            return false;
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched('');
        setRows(data);
    };

    return (
        <Container component='main' maxWidth='xl'>
            <CssBaseline />
            <div className={classes.paper}>
                <Typography variant='h2'>
                    User Login Issues
                </Typography>
            </div>
            { !message &&
                <Box mt={5}>
                        <SearchBar
                            value={searched}
                            onChange={(searchVal) => requestSearch(searchVal)}
                            onCancelSearch={() => cancelSearch()}
                            style={{width:"40%"}}
                        />
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            disableSelectionOnClick
                            autoHeight
                            autoPageSize
                        />
                    </div>
                </Box>
            }
            <div>{message}</div>
        </Container>
    );
};
