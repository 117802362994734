import React, { useContext, useState } from 'react'
import { FormContext } from './FormContext';
import { Grid, FormControl, TextField, makeStyles } from '@material-ui/core'

interface NumberFieldProps {
    label: string;
    name: string;
    value: string;
    minValue: number;
    maxValue: number;
    possibleValues: string[];
    type: string;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    }
}));

export default function NumberField(props: NumberFieldProps) {
    const [value, setValue] = useState(props.value);
    const { updatedSettings } = useContext(FormContext);
    const classes = useStyles();
    const minVal = props.minValue.toString();
    const maxVal = props.maxValue.toString();
    const stepVal = props.type === 'float' ? '0.1' : '1';
    const handleChangeInput = (name: any, event: any) => {
        setValue(event.target.value);
        updatedSettings[name] = event.target.value;
    }

    return (
        <Grid container spacing={3} justifyContent='center'>
            <Grid item lg={12}>
                <FormControl className={classes.formControl}>
                    <TextField
                        type='number'
                        id={props.name + '-label'}
                        label={props.label}
                        value={value}
                        onChange={event => handleChangeInput(props.name, event)}
                        InputProps={{ inputProps: { min: minVal, max: maxVal, step: stepVal, style: { textAlign: 'center' } } }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}
