import React, { useState, useEffect } from 'react'
import './App.css';
import { Link } from '@reach/router';
import {getPrepResponseHandler} from 'utils/fetch-api'
import {config} from 'utils/config';
import { FORGOT_PASSWORD_REQUEST_SENT_MSG, FORGOT_PASSWORD_ROUTE, HOME_ROUTE, USER_STORE } from 'utils/constants';
import { Button, Container, CssBaseline, Grid, makeStyles, TextField, Typography } from '@material-ui/core';

interface ForgotPasswordProps {
  path: string;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }
}));

export default function ForgotPassword({path}: ForgotPasswordProps) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  
  function validateForm() {
    return email.length > 0 ;
  }
 
  useEffect(()=> {
    setEmail('');
  },[]);

  function handleSubmit(event: any) {
    event.preventDefault();
    var loginEmail = email;
    setEmail('');
    const requestOptions= {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({userId: loginEmail})
    };
    
    return fetch(config.get().apiBaseURL + FORGOT_PASSWORD_ROUTE, requestOptions)
      .then(getPrepResponseHandler())
      .then( () => {
            console.log ('Response was OK');
            setMessage(FORGOT_PASSWORD_REQUEST_SENT_MSG);
      })
      .catch(error => {
          console.log(error);
      });
  }

  const classes = useStyles();

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div>{message}</div>
      {
        !localStorage.getItem(USER_STORE) && !message &&
        <form onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Username or Email'
            name='email'
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={!validateForm}
          >
            Continue
          </Button>
          <Grid container>
            <Grid item xs>
              <Typography variant='body2'>
                <Link to={HOME_ROUTE}>
                  Cancel
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      }
      {
        (message.length > 0) &&
        <Grid container>
          <Grid item xs>
            <Typography variant='body2'>
              <Link to={HOME_ROUTE}>
                Back to Login
              </Link>
            </Typography>
          </Grid>
        </Grid>
      }
    </Container>
  );
}
